/* global __lodash__, moment, axios, Component */
// eslint-disable-next-line no-undef
class CarComponentB2C extends Component {

    type() {
        return "component";
    }

    data() {
        return {
            currentStep:1,
            minStep:1,
            maxStep:3,
            pricesLoaded:false,
            status:'view',
            result: '',
            detailItem:null,
            showDetail:true,
            sucessCreate:false,
            completeDetail:false,
            salesOrder :'',
            orderComment :'',
            // eslint-disable-next-line no-undef
            orderCampaign:null,
            deliveryDate: moment().format('YYYY-MM-DD'),
            TaxRegTypes:['Reg.Company', 'Final Consumer', 'IVA No Resp.', 'Exempt', 'Resp. no Insc.', 'Monotributo', 'International Exempt', '', 'Sujeto no Categorizado'],
            errors:[],
            successMessage:"Congratulation, Sales Order #({{$1}}) was created",
            paymentMsg:null,
            paymentIsProcess:false,
            doOnlinePayment:false,
            orderTemplateCopy:null,
            mpdata:{
                salesorder:null,
                amount:0,
                email:"test@dos.com" //Mail del cliente comprados.
            },
            searchMessage:null,
            tempItemCode:null,
            tempItemQty:0,
            currentPayTerm: null,
            discountAmount: null,
            shipCostAmount: null,
            giftCardCode:null,
            payTermSelectedCode:null
        };
    }

    static name() {
        return "CarModalComponent";
    }

    static async loadData() {
        return {};
    }

    static componentName() {
        return "CarModalComponent";
    }

    mounted() {
        return async function () {
            this.subscribeEvent('deliveryselected',this.calculateOrderTotals);
            this.subscribeEvent('shipModeChange',this.calculateShipCost);

            this.discountThrottled = __lodash__.throttle(this.addDiscount,1800, {'trailing': false});

            this.checkItemInCar();
            this.loadPrices();
            let eventObj = {};
            eventObj[`order_PayTerm`] = this.payTerms[0].fields.PayTermCode;
            this.$store.dispatch('updateOrderDeliveryData',eventObj);
            if(this.$store.state.order_Campaign) {
                this.orderCampaign = this.$store.state.order_Campaign;
                this.calculateDiscount();
            }
            if(!this.$store.state.carOrderTemplate){
                this.calculateOrderTotals(true);
            }
        };
    }

    goToShop(){
        this.$router.push('/items');
    }

    loadDefaultPayTerm() {
        if(this.$store.state.order_PayTerm)
            this.payTermSelectedCode = this.$store.state.order_PayTerm;

        if(!this.payTermSelectedCode) {
            for(let payterm of this.payTerms)
                if(payterm.fields.RequireOnlinePayment) {
                    this.payTermSelectedCode = payterm.fields.PayTermCode;
                    break;
                }
        }
    }

    updated(){
        return async function (){
            if(!this.$store.state.session && this.$store.state.appState === 'ready') {
                this.$store.state.redirectAfterLogin = "/badges";
                this.$router.push('/login');
            }
        };
    }

    activeDayByZone(){
        let selectedShipMode = this.$store.state.order_ShipMode;
        let shipMode = __lodash__.find(this.$store.state.shipModes,function (shipMode){
            return shipMode.ShipModeCode === selectedShipMode;
        });

        if(shipMode && shipMode.OfficeCode)
            return false;

        let dayMap = this.$store.getters.getDaysByZone;
        for(let day of Object.keys(dayMap)) {
            if(dayMap[day])
                return true;
        }
        return false;
    }

    checkStepConditions() {

        if (this.currentStep === 2) {
            if (this.$store.getters.getShipModes.length > 0  && !this.$store.state.order_ShipMode) {
                this.$store.dispatch('showNotificacion', {
                    title: this.tr('Ship Mode'),
                    message: this.tr(["Please select a {{$1}}", this.tr('Ship Mode')]),
                    type: 'info'
                });
                return false;
            }

            if (this.$store.state.order_PayTerm === null) {
                this.$store.dispatch('showNotificacion', {
                    title: this.tr('Pay Mode'),
                    message: this.tr(["Please select a {{$1}}", this.tr('Pay Mode')]),
                    type: 'info'
                });
                return false;
            }

            if (this.$store.state.requireCenterCost && (!this.$store.state.order_CenterCost || this.$store.state.order_CenterCost === '')) {
                this.$store.dispatch('showNotificacion', {
                    title: this.tr('Cost Center'),
                    message: this.tr(["Please select a {{$1}}", this.tr('Cost Center')]),
                    type: 'info'
                });
                return false;
            }
            let checkPaymentDay = this.activeDayByZone();
            if (checkPaymentDay && (!this.$store.state.order_DelDay || this.$store.state.order_DelDay === '')) {
                this.$store.dispatch('showNotificacion', {
                    title: this.tr('Delivery Day'),
                    message: this.tr(["Please select a {{$1}}", this.tr('Delivery Day')]),
                    type: 'info'
                });
                return false;
            }
        }
        return true;
    }

    payAndClose(e) {
        let res = this.checkStepConditions();
        if(!res)
            return false;
        if(this.$store.state.order_DelAddressCode){
            this.status = "loading";
            let payload = this.getOrderPayload();
            this.nextStep();
            let self = this;
            this.$store.dispatch('toogleLockScreen',null);
            this.$store.dispatch('createSaleOrder',payload).then((result)=>{
                if(!result.error)
                    result.error = [];
                this.result = result;
                if(result.error.length > 0) {
                    let processError = [];
                    for (let err of result.error) {
                        if (typeof (err) === "string" || Array.isArray(err))
                            processError.push(this.tr(err));
                        else {
                            if (err.hasOwnProperty('code')) {
                                switch (err.code) {
                                    case "NOTITEMSTOCK":{
                                        for(let item of err.params.webmessage) {
                                            let errorData = [err.code];
                                            let dataError = item.split("*-*-*");
                                            let storeItem = this.$store.getters.getItemByCode(dataError[0]);
                                            if(storeItem)
                                                errorData.push(storeItem.Name);
                                            else 
                                                errorData.push(dataError[0]);
                                            errorData.push(dataError[1]);
                                            processError.push(this.tr(errorData));
                                        }
                                        break;
                                    }
                                    case "RESERVESSTOCKMISSING": {
                                        let errorData = [err.code];
                                        let storeItem = this.$store.getters.getItemByCode(err.params.FieldName);
                                        if(storeItem)
                                            errorData.push('"' + storeItem.Name + '"');
                                        else
                                            errorData.push(err.params.FieldName);
                                        processError.push(this.tr(errorData));
                                        break;
                                    }
                                    // case "LINKTOINVALIDVALUEERR": {
                                    //     let label = err.errorParams.FieldName;
                                    //     message = message.replace('%linkValue%', err.errorParams.FieldValue);
                                    //     message = message.replace('%linkToField%', oo.tr(label));
                                    //     message = message.replace('%linkRegister%', oo.tr(rec.name()));
                                    //     message = message.replace('%linkRecordId%', rec.getPortableId());
                                    //     break;
                                    // }
                                    default:
                                        processError.push(this.tr([err.code].concat(Object.values(err.params))));
                                }
                            }
                        }
                    }
                    result.error = processError;
                }
                if (result.success) {
                    this.gtmTrackEcommercePurchase(this.result.so);
                    this.$store.commit('clearItemToCar', 'result');
                    this.$store.commit('clearSalesOrderFields');
                    this.status = "completed";
                    this.orderTemplateCopy = null;
                    this.doOnlinePayment = this.$store.getters.getOnlinePayTerms.indexOf(this.result.so.fields.PayTerm) != -1 && this.result.so.fields.OrderStatus != this.$store.getters.getSetting.PreAutorizationStatus;
                    if(this.doOnlinePayment) {
                        let mpdata = {};
                        mpdata.email = "";
                        mpdata.salesorder = this.result.so.fields.SerNr;
                        mpdata.amount = this.result.so.fields.Total;
                        this.mpdata = mpdata;
                    }
                } else {
                    self.status = "failure";
                    this.$store.commit('clearItemToCar', 'failure');
                    this.$store.commit('clearSalesOrderFields');
                }
                this.$store.dispatch('toogleLockScreen',null);
            });
        }
        else{
            this.$store.dispatch('showNotificacion',{title:this.tr('Delivery Address'), message: this.tr("Select a Delivery Address or adding a new"),type: 'info'});
        }

    }

    async addDiscount(){
        if(this.orderCampaign === null) {
            this.$store.dispatch('showNotificacion', {
                title: `Coupon`,
                message: "Complete Discount Code",
                type: 'info'
            });
            return null;
        }

        this.$store.dispatch('toogleLockScreen',null);
        let orderCampaign = this.orderCampaign;
        if(this.$store.getters.getSetting.UseDiscountNotCapitalice)
            orderCampaign = orderCampaign.toLowerCase();

        let res = {data: {success:false, error:""}};
        if(this.orderCampaign)
            res = await axios.get('/ecommerce/api/checkCoupon/' + orderCampaign);
        let payload = this.getOrderPayload();
        if(res.data.success) {
            if (res.data.campaign)
                this.$store.commit('updateCampaignApplied', res.data.campaign.fields);
            payload.Campaign = orderCampaign;
            this.$store.dispatch('loadOrderTotals', payload).then((newOrder)=>{
                this.orderTemplateCopy = newOrder;
                this.calculateDiscountAmount();
                if(this.orderCampaign && this.getDiscountCoupon)
                    this.$store.dispatch('showNotificacion',{title:`Coupon`, message: ["Discount Applied of $ {{$1}}",this.getDiscountCoupon] ,type: 'info'});
                else {
                    this.$store.dispatch('updateOrderDeliveryData',{'order_Campaign':null});
                    this.orderCampaign = null;
                    this.$store.dispatch('showNotificacion', {
                        title: `Coupon`,
                        message: "Coupon does not meet the requirement to apply",
                        type: 'warning'
                    });
                }
                this.$store.dispatch('toogleLockScreen',null);
            });
        }
        else {
            if(this.discountAmount != null) {
                this.$store.dispatch('updateOrderDeliveryData',{'order_Campaign':null});
                payload.Campaign = orderCampaign;
                this.$store.dispatch('loadOrderTotals', payload).then((newOrder)=>{
                    this.orderTemplateCopy = newOrder;
                    this.calculateDiscountAmount();
                    this.orderCampaign = null;
                    this.$store.dispatch('showNotificacion',{title:`Coupon`, message: res.data.error,type: 'error'});
                    this.$store.dispatch('toogleLockScreen',null);
                });
            }
            else
                this.$store.dispatch('toogleLockScreen',null);
        }
    }

    async calculateShipCost(shipModeCode){
        let eventObj = {};
        eventObj[`order_ShipMode`] = shipModeCode;
        this.$store.dispatch('toogleLockScreen',null);
        await this.$store.dispatch('updateOrderDeliveryData',eventObj);
        let payload =  this.getOrderPayload();
        payload.ShipMode = shipModeCode;
        let newOrder = await this.$store.dispatch('loadOrderTotals',payload);
        this.orderTemplateCopy = window.__lodash__.cloneDeep(newOrder);
        this.calculateShipCostAmount();
        this.$store.dispatch('toogleLockScreen',null);
    }

    async calculateDiscount(){
        await this.discountThrottled();
    }

    addGiftcard(){
        this.$store.dispatch('ItemGiftcardAddToCar',this.giftCardCode);
    }

    addItemToCar(){
        let self = this;
        if(this.tempItemCode && this.tempItemQty > 0) {
            let item = this.$store.getters.getItemByCode(this.tempItemCode);
            if(item) {
                if(isNaN(item.carCant))
                    item.carCant = this.tempItemQty;
                else
                    item.carCant = item.carCant + this.tempItemQty;
                this.$store.dispatch('ItemComponentAddToCar', item);
                this.tempItemCode = null;
                this.tempItemQty = 0;
            }
            else {
                this.searchMessage = this.tr(["Item not found ({{$1}})", this.tempItemCode]);
                this.$store.dispatch('showNotificacion', {
                    title: this.tr('Not Found'),
                    message: this.tr(["Item not found ({{$1}})", this.tempItemCode]),
                    type: 'info'
                });
                setTimeout(()=>{
                    self.searchMessage = null;
                },8000);
            }
        }
        else{
            this.searchMessage = this.tr("The Item Qty must be greater than zero");
            setTimeout(()=>{
                self.searchMessage = null;
            },8000);
        }

    }

    alertShowMessage(alertMsg){
        this.$store.dispatch('showNotificacion',{title:this.tr(`Information`), message: alertMsg,type: 'warning'});
    }

    checkItemInCar(){
        for (let carItem of this.$store.getters.getItemCar) {
            if(!carItem.id)
                carItem.id = carItem.item.ArtCode;
            if(!carItem.item.Code)
                carItem.item.Code = carItem.item.ArtCode;
            if(this.$store.getters.getItemByCode(carItem.id.toString()))
                if(carItem.item)
                    carItem.item.carCant = carItem.cant;
            carItem.message = null;
        }
    }

    getMaxStockByCode(itemCode){
        let giftCardItem = this.$store.getters.getGiftCardItem;
        if(this.$store.getters.getSetting.ControlStockForSales &&  !(giftCardItem && giftCardItem.fields.Code == itemCode)) {
            let itemInStore = this.$store.state.items_store.get(itemCode);
            if(itemInStore) {
                if (itemInStore.Reserved)
                    return itemInStore.Qty - itemInStore.Reserved;
                return itemInStore.Qty;
            }
        }
        return this.$store.getters.getMaxQtyItem;
    }

    loadPrices(){
        let itemCodes = new Map();
        let errors = [];
        let clearCodes = [];
        let sessionCartItems = [];
        for (let carItem of this.itemsSelected){
            if(!carItem.id)
                carItem.id = carItem.item.ArtCode;
            if(!carItem.item.Code)
                carItem.item.Code = carItem.item.ArtCode;
            if (!itemCodes.has(carItem.id)) {
                let tempItem = this.$store.getters.getItemByCode(carItem.id.toString());
                if (tempItem) {
                    sessionCartItems.push(carItem);
                    if((tempItem && tempItem.isCalculate === false))
                        itemCodes.set(tempItem.Code.toString(), tempItem);
                }else if(carItem.giftcardCode){
                    sessionCartItems.push(carItem);
                    itemCodes.set(carItem.item.Code, carItem);
                } else{
                    carItem.cant = 0;
                    this.deleteItemFromCar(carItem.id);
                    let error_msg = [`Item {{$1}} was removed from car`,carItem.id];
                    errors.push(error_msg);
                }
            }
        }
        this.$store.commit('updateSessionCar',sessionCartItems);
        let self = this;
        if(!this.pricesLoaded && Array.from(itemCodes.values()).length > 0){
            //this.$store.dispatch( 'toogleLockScreen',this.tr( 'Loading Prices' ) );
            this.$store.dispatch("updatePrices", Array.from(itemCodes.values()))
                .then(function (response){
                    //self.$store.dispatch( 'toogleLockScreen',"" );
                    self.pricesLoaded = true;
                    self.$store.dispatch('reloadCarPrice',clearCodes);
                    self.errors = errors;
                });
        }
    }

    checkStock(){

        let itemCodes = new Map();
        let errors = [];
        let clearCodes = [];

        for (let carItem of this.itemsSelected){
            if (!itemCodes.has(carItem.id)) {
                let tempItem = this.$store.getters.getItemByCode(carItem.id);

                if (tempItem) {
                    if(this.$store.getters.getControlStock){
                        if(!tempItem.Qty || tempItem.Qty === 0) {
                            clearCodes.push(carItem.id);
                            let error_msg = `++ ${this.tr('Item')} ${carItem.id}. ${this.tr('ITEM_STOCK_ZERO')}++`;
                            carItem.cant = 0;
                            errors.push(error_msg);
                        }
                        if(tempItem.Qty < carItem.cant)
                            carItem.cant = tempItem.Qty;

                    }
                    else
                        itemCodes.set(carItem.id, tempItem);
                }
                else {
                    clearCodes.push(carItem.id);
                    let error_msg = `** ${this.tr('Item')} ${carItem.id}. ${this.tr('ITEM_FAVORITE_REMOVE')}** `;
                    carItem.cant = 0;
                    errors.push(error_msg);
                }
            }
        }
        return errors.length === 0;
    }

    updateStatusCar() {
        this.status = this.$store.getters.getCarStatus;
    }

    getOrderPayload() {
        let payload = {
            needReload:true,
            DeliveryDate: this.deliveryDate,
            Comment: this.orderComment,
            SalesOrder : this.salesOrder,
            Campaign: this.orderCampaign,
        };
        if(this.orderCampaignActive)
            payload["Campaign"] = this.orderCampaign;
        return payload;
    }

    async calculateOrderTotals(needReload = false){
        this.$store.dispatch('toogleLockScreen',null);
        let payload = this. getOrderPayload();
        this.$store.dispatch('loadOrderTotals',payload).then((newOrder)=>{
            if(newOrder){
                this.orderTemplateCopy = Object.assign({},newOrder);
                this.calculateDiscountAmount();
                this.calculateShipCostAmount();
                this.$store.dispatch('toogleLockScreen',null);
            }
        });
    }

    updateCarQty(event){
        let maxQty = this.getMaxStockByCode(event.target.id);
        if(this._resetBlurInterval)
            clearInterval(this._resetBlurInterval);
        
        if(event.target.valueAsNumber === event.target.oldvalue || event.target.valueAsNumber === event.target._value)
            return;

        this.$store.dispatch('toogleLockScreen',"");
        if(parseInt(event.target.value) > maxQty) {
            this.$store.dispatch('toogleLockScreen',null);
            event.target.value = event.target.oldvalue;
            this.$store.dispatch('showNotificacion',{title:this.tr(`Qty`), message:this.tr(["The article qty can't be bigger than {{$1}}",maxQty]),type: 'error'});
            return;
        }

        let newItemQty = event.target.valueAsNumber; //this.positiveIntegerformat(event.target.value,-1);
        if(isNaN(newItemQty) || newItemQty <= 0) {
            this.$store.dispatch('toogleLockScreen',null);
            this.$store.dispatch('showNotificacion',{title:this.tr(`Qty`), message:this.tr("Incorrent Qty Value"),type: 'error'});
            return;
        }
        if(this.orderTemplateCopy) {
            for(let item of this.orderTemplateCopy.fields.Items){
                if (item.fields.ArtCode === event.target.id) {
                    if (this.controlStock) {
                        let storeItem = this.$store.getters.getItemByCode(item.fields.ArtCode);
                        const maxQty = this.getMaxStockByCode(storeItem.Code);
                        if(parseInt(event.target.value) > storeItem.Qty) {
                            this.alertShowMessage(["The article qty can't be bigger than {{$1}}",maxQty]);
                            newItemQty = storeItem.Qty;
                        }
                    } else
                        newItemQty = event.target.value;
                    item.fields.Qty = newItemQty;
                }
            }
        }
        let storeItem = this.$store.getters.getItemByCode(event.target.id);
        let cartItem = this.$store.getters.getItemCar.filter(x=>x.id == event.target.id)[0];
        if(newItemQty > cartItem.cant)
            this.gtmTrackEcommerceAddToCart(storeItem, newItemQty - cartItem.cant, this.orderTemplate.fields.Currency, storeItem.Price * (newItemQty - cartItem.cant));
        else
            this.gtmTrackEcommerceRemoveFromCart(storeItem, cartItem.cant - newItemQty , this.orderTemplate.fields.Currency, storeItem.Price * (cartItem.cant - newItemQty));
        this.$store.dispatch('changeCartQty',{itemCode:event.target.id,qty:parseInt(newItemQty),action:'update'}).then((result)=>{
            this.$store.dispatch('toogleLockScreen',null);
            this.calculateOrderTotals(true);
        });
    }

    itemQtyIncrement(itemCode){
        this.$store.dispatch('changeCartQty',{itemCode:itemCode,action:'up'}).then(()=>{
            this.calculateOrderTotals(true);
        });
    }

    itemQtyDecrement(itemCode){
        this.$store.dispatch('changeCartQty',{itemCode:itemCode,action:'down'}).then(()=>{
            this.calculateOrderTotals(true);
        });
    }

    deleteItemFromCar(itemCode){
        let storeItem = this.$store.getters.getItemByCode(itemCode);
        let carItem = this.$store.getters.getItemCar.filter(x=>x.id == itemCode);
        if(storeItem)
            this.gtmTrackEcommerceRemoveFromCart(storeItem, this.orderTemplate.fields.Currency, storeItem.Price * carItem.cant);
        this.$store.dispatch('changeCartQty',{itemCode:itemCode,action:'delete'}).then(()=>{
            this.calculateOrderTotals(true);
        });
    }

    clearCar(){
        let iter = 1;
        for (let item of this.orderTemplate.fields.Items) {
            let storeItem = this.$store.getters.getItemByCode(item.fields.ArtCode);
            if (storeItem) {
                setTimeout(()=>this.gtmTrackEcommerceRemoveFromCart(storeItem, item.fields.Qty, this.orderTemplate.fields.Currency, item.fields.RowNet), 2500 * iter);
                iter++;
            }
        }
        this.$store.commit('resetItemToCar');
        this.$store.commit('clearSalesOrderFields');
        this.calculateOrderTotals(true);
    }

    close() {
        this.$store.commit('updateCarStatus','view');
        this.$router.go(-1);
    }

    showItemPrice(item){
        let price = item.Price;
        if(this.$store.getters.getPriceToShow)
            price = item.RowTotal;
        else{
            if(item.RowNet)
                price = item.RowNet;
        }
        return parseFloat(Math.round(price * 100) / 100).toFixed(2);
    }

    rowPriceTotal(item){
        if(item.cant)
            return parseFloat(parseFloat(item.cant) * parseFloat(item.item.Price) * 100 / 100).toFixed(2);
        else
            return 0;
    }

    showPriceLabel() {
        if(this.$store.getters.getPriceToShow)
            return '(IVA INC)';
        else{
            return '(IVA EXC)';
        }
    }

    goToStep(newState){

        switch(newState){
            case 'detail':
                if(!this.sucessCreate) {
                    break;
                }
                this.status = newState;
                break;
            case 'orderResult':
                if(!this.sucessCreate) {
                    this.status = 'orderResult';
                    break;
                }
                if(!this.completeDetail) {
                    this.status = 'detail';
                    break;
                }
                this.status = newState;
        }
        this.$store.commit('updateCarStatus',this.status);
    }

    viewDetailItem(item){

        this.detailItem = item;
        if(this.detailItem)
            this.emitEvent('modal-detail-open');
    }

    closeDetail(){
        this.detailItem = null;
    }

    navListItems(step) {
        if(this.currentStep > step && this.currentStep < 3)
            this.currentStep = step;
        return false;
    }

    prevStep(force = false) {
        if(force) {
            if (this.currentStep > this.minStep)
                this.currentStep = this.currentStep - 1;
        }
        else {
            if (this.currentStep < this.maxStep) {
                if (this.currentStep > this.minStep)
                    this.currentStep = this.currentStep - 1;
                else
                    this.currentStep = this.minStep;
            }
        }
    }

    nextStep(e) {
        let res = this.checkStepConditions();
        if(!res)
            return false;
        if(this.currentStep < this.maxStep) {
            if (this.currentStep < this.maxStep)
                this.currentStep = this.currentStep + 1;
            else
                this.currentStep = this.minStep;
        }
    }

    paymentProcess(event){
        this.paymentMsg = [];
        this.$store.dispatch('toogleLockScreen', "");
        if(event.actionResult && event.paymentResult.ok) {
            this.doOnlinePayment = false;
            this.paymentIsProcess = true;
            this.paymentMsg = null;

            this.$store.dispatch("approvedOrder",this.result.so.fields.SerNr);
        }
        else{
            this.doOnlinePayment = true;
            if(event.cause)
                this.paymentMsg = event.cause;
            if(event.paymentResult && event.paymentResult.message) {
                this.paymentMsg = event.paymentResult.message;
            }
            if (event.error){
                this.error = true;
                this.paymentMsg = event.cause[0].description;
            }
            if (event.hasOwnProperty("paymentResult")){
                this.error = true;
                this.paymentMsg = event.paymentResult.message;
            }
            window.scrollTo(0,0);
        }
    }

    paymentInit(){
        this.$store.dispatch('toogleLockScreen',this.tr('Payment Processing'));
    }

    updateSelectValues(updateValue){
        let eventObj = {};
        eventObj[`order_${updateValue}`] = event.target.value;
        this.$store.dispatch('updateOrderDeliveryData',eventObj);
    }

    getPayTermObj(){
        return this.$store.state.setting.PayTermRow.find(row => row.fields.PayTermCode === this.result.so.fields.PayTerm);
    }

    calculateDiscountAmount(){
        if(this.orderTemplateCopy !== null && this.orderTemplateCopy.fields.Items){
            let items = __lodash__.cloneDeep(this.orderTemplateCopy.fields.Items);
            let itemCodes = this.$store.state.discountItemCode.split(',');
            let discountItem = __lodash__.find(items,function (row){
                return itemCodes.indexOf(row.fields.ArtCode) !== -1;
            });
            if(discountItem){
                if(this.$store.getters.getSetting.ShowPriceWithIVA)
                    this.discountAmount =  this.number_format(discountItem.fields.VATPrice,2);
                else
                    this.discountAmount = this.number_format(discountItem.fields.Price,2);
            }
            else
                this.discountAmount = 0;
        }
        else {
            this.discountAmount = 0;
        }
    }

    calculateShipCostAmount(){
        if(this.orderTemplateCopy && this.orderTemplateCopy.fields.Items && this.shipItemCode){
            let items = __lodash__.cloneDeep(this.orderTemplateCopy.fields.Items);
            let itemCodes = this.$store.state.shipItemCode.split(',');
            let shipCostItem = __lodash__.find(items,function (row){
                return itemCodes.indexOf(row.fields.ArtCode) !== -1;
            });

            if(shipCostItem) {
                if (this.$store.getters.getSetting.ShowPriceWithIVA)
                    this.shipCostAmount = this.number_format(shipCostItem.fields.VATPrice, 2);
                else
                    this.shipCostAmount = this.number_format(shipCostItem.fields.Price, 2);
            }
        }
        else
            this.shipCostAmount = null;
    }

    getPayTermName(payTermCode) {
        const payTermRow = this.$store.getters.getSetting.PayTermRow.filter(x=>x.fields.PayTermCode === payTermCode);
        if(payTermRow.length > 0)
            return payTermRow[0].fields.PayTermName;
        return payTermCode;
    }

    getShipModeName(shipModeCode) {
        const shipModes = this.$store.state.shipModes.filter(x=>x.ShipModeCode === shipModeCode);
        if(shipModes.length > 0)
            return shipModes[0].ShipModeName;
        return shipModeCode;
    }

    getOrderField(fieldName, defaultValue = null) {
        if(this.orderTemplate.fields)
            return this.orderTemplate.fields[fieldName];
        return defaultValue ? defaultValue : null;
    }

    getPayTermSurchargeLable() {
        const amount = parseFloat(this.getPayTermSurcharge);
        if (amount > 0)
            return this.tr(['Surcharge {{$1}}', this.getPayTermName(this.orderTemplate.fields.PayTerm)]);
        if (amount < 0)
            return this.tr(['Discount {{$1}}', this.getPayTermName(this.orderTemplate.fields.PayTerm)]);

    }

    autoResetBlur() {
        this._resetBlurInterval = setInterval(()=>document.activeElement.blur(), 4000);
    }

    getMethods() {
        return {
            getOrderPayload: this.getOrderPayload,
            getOrderField: this.getOrderField,
            loadDefaultPayTerm: this.loadDefaultPayTerm,
            goToShop: this.goToShop,
            calculateShipCostAmount:this.calculateShipCostAmount,
            calculateDiscountAmount:this.calculateDiscountAmount,
            getPayTermName: this.getPayTermName,
            getShipModeName: this.getShipModeName,
            getPayTermSurchargeLable:this.getPayTermSurchargeLable,
            activeDayByZone:this.activeDayByZone,
            addDiscount:this.addDiscount,
            calculateShipCost:this.calculateShipCost,
            calculateDiscount:this.calculateDiscount,
            checkStepConditions:this.checkStepConditions,
            payAndClose: this.payAndClose,
            addItemToCar:this.addItemToCar,
            alertShowMessage:this.alertShowMessage,
            checkItemInCar:this.checkItemInCar,
            getMaxStockByCode:this.getMaxStockByCode,
            loadPrices:this.loadPrices,
            checkStock:this.checkStock,
            updateStatusCar:this.updateStatusCar,
            calculateOrderTotals:this.calculateOrderTotals,
            updateCarQty:this.updateCarQty,
            autoResetBlur:this.autoResetBlur,
            itemQtyIncrement:this.itemQtyIncrement,
            itemQtyDecrement:this.itemQtyDecrement,
            deleteItemFromCar:this.deleteItemFromCar,
            clearCar:this.clearCar,
            showItemPrice:this.showItemPrice,
            rowPriceTotal:this.rowPriceTotal,
            showPriceLabel:this.showPriceLabel,
            goToStep:this.goToStep,
            viewDetailItem:this.viewDetailItem,
            closeDetail:this.closeDetail,
            navListItems:this.navListItems,
            prevStep:this.prevStep,
            nextStep:this.nextStep,
            paymentProcess:this.paymentProcess,
            paymentInit:this.paymentInit,
            updateSelectValues:this.updateSelectValues,
            getPayTermObj: this.getPayTermObj,
            addGiftcard: this.addGiftcard
        };
    }

    getComputed() {
        return {
            payTermSurchargeItemCode:function (){
                return this.$store.getters.getPayTermSurchargeItemCode;
            },
            discountItemCode:function (){
                return this.$store.getters.getDiscountItemCode;
            },
            shipItemCode:function (){
                return this.$store.getters.getShipItemCode;
            },
            getDiscountCoupon:function (){
                if(this.orderTemplateCopy && this.orderTemplateCopy.fields){
                    let items = __lodash__.cloneDeep(this.orderTemplateCopy.fields.Items);
                    let itemCodes = this.$store.state.discountItemCode.split(',');
                    let discountItem = __lodash__.find(items,function (row){
                        return itemCodes.indexOf(row.fields.ArtCode) !== -1;
                    });
                    if(discountItem){
                        if(this.$store.getters.getSetting.ShowPriceWithIVA)
                            this.discountAmount =  this.number_format(discountItem.fields.VATPrice,2);
                        else
                            this.discountAmount = this.number_format(discountItem.fields.Price,2);
                    }
                    else
                        this.discountAmount = 0;
                }
                else {
                    this.discountAmount = 0;
                }
                return this.discountAmount;
            },
            getShipCost:function () {
                if(this.orderTemplate && this.orderTemplate.fields && this.shipItemCode){
                    let items = __lodash__.cloneDeep(this.orderTemplate.fields.Items);
                    let shipModeCodes = this.shipItemCode.split(",");
                    let shipCostItem = __lodash__.find(items,function (cost){
                        return shipModeCodes.indexOf(cost.fields.ArtCode) !== -1;
                    });
                    if(shipCostItem) {
                        if (this.$store.getters.getSetting.ShowPriceWithIVA)
                            return this.number_format(shipCostItem.fields.VATPrice, 2);
                        else
                            return this.number_format(shipCostItem.fields.Price, 2);
                    }
                }
                return null;
            },
            getPayTermSurcharge:function () {
                if(this.orderTemplate && this.orderTemplate.fields && this.payTermSurchargeItemCode){
                    let items = __lodash__.cloneDeep(this.orderTemplate.fields.Items);
                    let surcharguesItemCodes = this.payTermSurchargeItemCode.split(",");
                    let surchageCostItem = __lodash__.find(items,function (cost){
                        return surcharguesItemCodes.indexOf(cost.fields.ArtCode) !== -1;
                    });
                    if(surchageCostItem) {
                        if (this.$store.getters.getSetting.ShowPriceWithIVA)
                            return this.number_format(surchageCostItem.fields.VATPrice, 2);
                        else
                            return this.number_format(surchageCostItem.fields.Price, 2);
                    }
                }
                return null;
            },
            getOrderItems:function () {
                if (this.orderTemplate && this.orderTemplate.fields) {
                    let items = __lodash__.cloneDeep(this.orderTemplate.fields.Items);
                    let discountItemCode = this.$store.state.discountItemCode;
                    let shipItemCode =  this.$store.state.shipItemCode;
                    let paytermSuperchargeItemCode  = this.payTermSurchargeItemCode;
                    __lodash__.remove(items,function (x) {
                        return x.fields.ArtCode === discountItemCode || x.fields.ArtCode === shipItemCode || x.fields.ArtCode === paytermSuperchargeItemCode;
                    });
                    return items;
                }
                return null;
            },
            payTerms(){
                let payterms = [];
                for(let payT of this.$store.state.setting.PayTermRow) {
                    if(payT.fields.ShipModes){
                        if(this.orderTemplateCopy && this.orderTemplateCopy.fields.ShipMode){
                            if(payT.fields.ShipModes.split(",").indexOf(this.orderTemplateCopy.fields.ShipMode) !== -1)
                                payterms.push(payT);
                        }
                    }
                    else
                        payterms.push(payT);
                }
                return payterms;
            },
            controlStock(){
                return this.$store.getters.getControlStock;
            },
            mpAvailable:function (){
                return 'mercadopagoCustom' in Vue.options.components;
            },
            redirectAvailable(){
                if(this.result.so) {
                    let pt = this.$store.state.setting.PayTermRow.find(row => row.fields.PayTermCode === this.result.so.fields.PayTerm);
                    if (pt && pt.fields.OppenRedirectEndPoint)
                        return this.$store.getters.getSetting.MercadoPagoMode === 2;
                }
            },
            viewIsLoadingOld:function (){

                for(let cartItem of this.itemsSelected) {
                    let storeItem = this.$store.getters.getItemByCode(cartItem.item.Code);

                    if (storeItem && storeItem.isCalculate === false) {
                        this.loadPrices();
                        return true;
                    }
                }
                return false;
            },
            viewIsLoading:function (){
                if(! this.orderTemplate)
                    return true;
                return false;
            },
            show(){
                return true;
                //return this.$store.getters.getCarModal
            },
            customer(){
                return this.$store.getters.getCustomer;
            },
            checkOutReady(){
                return this.$store.getters.getItemCar.length > 0;
            },
            orderTemplate(){
                if(this.orderTemplateCopy)
                    return this.orderTemplateCopy;
                return window.__lodash__.cloneDeep(this.$store.state.carOrderTemplate);
            },
            itemsSelected:function (){
                let items = this.$store.getters.getItemCar;
                let newitems = new Map();
                for(let item of items){
                    if(!newitems.has(item.item.Code))
                        newitems.set(item.item.Code,item);
                    else
                        newitems.get(item.item.Code).cant = newitems.get(item.item.Code).cant + item.cant;
                }
                return Array.from(newitems.values());
            },
            carTotalAmount: function (){
                return this.$store.state.total_in_car;
            },
            payTermFilter(){
                let filter = {};
                let settingPayTerm = this.$store.getters.getPayTerms;
                if(this.$store.state.customer)
                    settingPayTerm.push(this.$store.state.customer.PayTerm);
                filter['Code__in'] = settingPayTerm;
                return filter;
            },
            payTerm(){
                if(this.$store.state.order_PayTerm)
                    return this.$store.state.order_PayTerm;
                if(this.$store.state.customer)
                    return this.$store.state.customer.PayTerm;
                return null;
            },
            Currency:function () {
                return this.getOrderField('Currency', this.$store.getters.getCurrency);
            }
        };
    }

    getWatch() {
        return  {
            "orderCampaign":function () {
                this.orderCampaignActive = false;
            },
            "$store.getters.getCarAmount" : function (newValue, oldValue){
                this.calculateOrderTotals(true);
            },
            '$store.state.order_PayTerm':function (newValue, oldValue){
                this.calculateOrderTotals(true);
            },
            '$store.state.order_DelAddressCode':function (newValue, oldValue){
                this.calculateOrderTotals();
            }
        };
    }

    getTemplate(){
        return `<div id="carModal" class="row cart-items" v-if="!viewIsLoading">
                  <h5 class="col-12 title">{{tr("Your Purchase")}}</h5>
                  <div class="col-12">
                     <addSOFavModalComponent :from="'car-items'"></addSOFavModalComponent>
                     <template v-if="detailItem">
                        <carModalDetailComponent :item="detailItem" :closeCallback="closeDetail" ></carModalDetailComponent>
                    </template>
                    <template v-if="errors.length>0">
                        <div class="alert alert-warning alert-dismissible fade show" role="alert">
                            <template v-for="error of errors">
                                <strong>{{tr(error)}}</strong>
                            </template>
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </template>
                     <div class="col-12 px-0 px-sm-3">
                          <div class="stepwizard">
                              <div class="stepwizard-row setup-panel">
                                  <div class="stepwizard-step">
                                      <button @click="navListItems(1)" :class="{'btn': true, 'active': currentStep==1 , 'disabled': currentStep<1}" :disabled="currentStep<1">1</button>
                                      <p>{{tr("Items in Cart")}}</p>
                                  </div>
                                  <div class="stepwizard-step">
                                      <button @click="navListItems(2)" :class="{'btn': true, 'active': currentStep==2 , 'disabled': currentStep<2}" :disabled="currentStep<2">2</button>
                                      <p>{{tr("Delivery Data")}}</p>
                                  </div>
                                  <div class="stepwizard-step">
                                      <button  @click="navListItems(3)" :class="{'btn': true, 'active': currentStep==3 , 'disabled': currentStep<3}" :disabled="currentStep>3">3</button>
                                      <p>{{tr('Payment')}}</p>
                                  </div>
                              </div>
                          </div>
                          <div class="stepwizard-content row">
                             <div class="col-12 setup-content order-items" id="step-1" v-if="currentStep==1">
                                 <div class="table-responsive">
                                    <div v-if="$store.getters.useDiscountCoupon || $store.getters.useGiftcard" class="col-12 px-1 px-md-3">
                                        <div class="bordered my-2">
                                            <div class="form-row px-2 py-2">
                                                <div v-if="$store.getters.useDiscountCoupon" class="col-12 px-3" :class="{'col-md-6 mb-4 mb-md-0': $store.getters.useGiftcard}">
                                                    <h6 class="col-12 mb-3 px-0 align-items-center d-flex"><i class="delivery-icon fas fa-tag fa-2x mr-3" style="font-size: 25px;"></i>{{tr('Discount')}}</h6>
                                                    <div class="form-row">
                                                        <input class="col-8 form-control" :placeholder="tr('Enter here your discount code')" v-model="orderCampaign" >
                                                        <button type="button" class="col ml-2 btn btn-custom" @click="calculateDiscount">{{tr("Apply")}}</button>
                                                    </div>
                                                </div>
                                                <div v-if="$store.getters.useGiftcard" class="col-12 px-3" :class="{'col-md-6': $store.getters.useDiscountCoupon}">
                                                    <h6 class="col-12 mb-3 px-0 align-items-center d-flex"><i class="delivery-icon fas fa-gift fa-2x mr-3" style="font-size: 25px;"></i>{{tr('Giftcard')}}</h6>
                                                    <div class="form-row">
                                                        <input class="col-8 form-control" :placeholder="tr('Enter here your Giftcard code')" v-model="giftCardCode">
                                                        <button type="button" class="col ml-2 btn btn-custom btn btn-custom" @click="addGiftcard">{{tr("Apply")}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     <table class="table table-striped mb-0">
                                         <thead>
                                             <tr>
                                                 <th scope="col" class="center">{{tr("Item")}}</th>
                                                 <th scope="col" class="d-none d-sm-block">{{tr("Unit")}}</th>
                                                 <th scope="col" class="text-right">{{tr("Price")}}</th>
                                                 <th scope="col" colspan="3" class="text-center">{{tr("Qty")}}</th>
                                                 <th scope="col" class="text-right">{{tr("Total")}}</th>
                                                 <th></th>
                                             </tr>
                                         </thead>
                                         <tbody v-if="orderTemplate">
                                            <template v-for="item in getOrderItems">
                                                 <template v-if="item.message">
                                                   <tr :key="'carItem-'+item.fields.ArtCode" :title="item.message" :class="{ 'text-danger': item.message }" >
                                                       <td colspan="4">
                                                          {{item.fields.Name}}
                                                          <!--<a href="#" class="item-link" :class="{ 'text-danger': item.message }" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}} </a>-->
                                                       </td>
                                                       <td colspan="3">
                                                          {{tr(item.message)}}
                                                       </td>
                                                        <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                          <span class="row"  :title="tr('Delete Element from Car')">
                                                              <i class="fas fa-trash"></i>
                                                          </span>
                                                       </td>
                                                    </tr>
                                                 </template>
                                                 <template v-else>
                                                     <tr :key="'carItem-'+item.fields.ArtCode">
                                                       <td>
                                                          {{item.fields.Name}}
                                                       </td>
                                                       <td scope="row" class="d-none d-sm-block">{{item.fields.Unit}}</td>
                                                       <td scope="row" class="text-right" >
                                                           <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                               <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                    {{number_format(item.fields.VATPrice,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                               </template>
                                                               <template v-else>
                                                                    {{number_format(item.fields.Price,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                                </template>
                                                           </template>
                                                           <template v-else>
                                                               --
                                                           </template>
                                                       </td>
                                                       <td scope="row" colspan="3" class="center" >
                                                          <div class="form-inline row  justify-content-center">
                                                              <label class="sr-only form-control" @click="()=>itemQtyIncrement(item.fields.ArtCode)">
                                                                  <i class="fas fa-plus-circle waves-effect"></i>
                                                              </label>
                                                              <input :key="'carItemInput-'+item.fields.ArtCode" type="number" :id="item.fields.ArtCode" class="form-control col-8" min="1"  :max="getMaxStockByCode(item.fields.ArtCode)" :value="parseInt(item.fields.Qty)" @change="updateCarQty" @focusin="autoResetBlur" @focusout="updateCarQty">
                                                              <label class="sr-only form-control" @click="()=>itemQtyDecrement(item.fields.ArtCode)" >
                                                                  <i class="fas fa-minus-circle waves-effect"></i>
                                                              </label>
                                                           </div>
                                                       </td>
                                                       <td class="text-right">
                                                           <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                               <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                    {{number_format(item.fields.RowTotal,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                               </template>
                                                               <template v-else>
                                                                    {{number_format(item.fields.RowNet,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                                </template>
                                                           </template>
                                                           <template v-else>
                                                               --
                                                           </template>
                                                       </td>
                                                       <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                          <span class="delet-icon"  :title="tr('Delete Element from Car')">
                                                              <i class="fas fa-trash"></i>
                                                          </span>
                                                       </td>
                                                     </tr>
                                                 </template>
                                            </template>
                                            <tr v-if="$store.getters.showSubTotalInOrderDetail">
                                                 <td colspan="9" class="text-right" >
                                                      <strong class="mr-2">Sub Total</strong>
                                                      <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                          <strong>$ {{number_format(getOrderField('SubTotal',0),2)}} <small v-if="Currency"> {{Currency}}</small></strong> 
                                                      </template>
                                                      <template v-else>
                                                          --
                                                      </template>
                                                  </td>
                                            </tr>
                                            <tr v-if="getShipCost">
                                                <td colspan="8" class="text-left text-md-right">
                                                    <strong>{{tr("Ship Cost")}}:$ {{getShipCost}} <small v-if="Currency"> {{Currency}}</small></strong> 
                                                </td>
                                            </tr>
                                            <tr v-if="$store.getters.showPayTermSurchargeInOrder && getPayTermSurcharge !== null">
                                                <td colspan="8" class="text-left text-md-right">
                                                     <strong>{{getPayTermSurchargeLable('MP')}}: $ {{getPayTermSurcharge}} <small v-if="Currency"> {{Currency}}</small></strong>
                                                </td>
                                            </tr>
                                            <tr v-if="getDiscountCoupon">
                                                <td colspan="8" class="text-left text-md-right">
                                                    <strong>{{tr("Discount")}}: $ {{getDiscountCoupon}} <small v-if="Currency"> {{Currency}}</small></strong>
                                                </td>
                                            </tr>
                                            <tr v-if="$store.getters.showIVAInOrder">
                                                <td colspan="9" class="text-right">
                                                    <strong class="mr-2">Total IVA</strong>
                                                    <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                        <strong>$ {{number_format(getOrderField('VATTotal',0),2)}} <small v-if="Currency"> {{Currency}}</small></strong> 
                                                    </template>
                                                    <template v-else>
                                                        --
                                                    </template>
                                                </td>
                                            </tr>
                                            <tr v-if="$store.getters.showTaxInOrder">
                                                <td colspan="9" class="text-right">
                                                    <strong class="mr-2">Percepciones</strong>
                                                    <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                        <strong>$ {{number_format(getOrderField('TotalTax',0),2)}} <small v-if="Currency"> {{Currency}}</small></strong> 
                                                    </template>
                                                    <template v-else>
                                                        --
                                                    </template>
                                                </td>
                                            </tr>                                            
                                            <tr>
                                                <td colspan="8" class="text-left text-md-right">
                                                   <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                        <strong class="mr-2">Total</strong>
                                                   </template>
                                                   <template v-else>
                                                        <strong class="mr-2">Total (IVA EXC)</strong>
                                                    </template>
                                                   <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                       <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                            <strong>$ {{number_format(getOrderField('Total',0),2)}} <small v-if="Currency"> {{Currency}}</small></strong> 
                                                       </template>
                                                       <template v-else>
                                                            <strong>$ {{number_format(getOrderField('SubTotal',0),2)}} <small v-if="Currency"> {{Currency}}</small></strong> 
                                                        </template>
                                                   </template>
                                                   <template v-else>
                                                        --
                                                   </template>
                                                </td>
                                            </tr>
                                         </tbody>
                                     </table>
                                     <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                        <div class="payment-message text-left text-md-right mb-3 px-md-3 ">Todos los precios tienen IVA incluído</div>
                                    </template>
                                    <template v-else>
                                        <div class="payment-message text-left text-md-right mb-3 px-md-3 ">Todos los precios tienen IVA excluido</div>
                                    </template>
                                 </div>
                                 <div class="row mt-2">
                                      <div class="col-12 col-md-6 text-center text-md-left">
                                          <button key="btn-1-step-1" @click="goToShop()" class="btn btn-secondary mb-2 continue-shopping">
                                                <span class="icon"><i class="fa-icon fas fa-cart-plus mr-1"></i></span>
                                                {{tr('Continue Shopping')}}
                                          </button>
                                          <button key="btn-2-step-1" @click="clearCar()" class="btn btn-secondary mb-2 clear">
                                              <i class="fas fa-trash mr-1"></i>
                                              {{tr('Clear')}}
                                          </button>
                                      </div>
                                      <div class="col-12 col-md-6 order-first order-md-last text-center text-md-right">
                                      <button key="brn-3-step-1" :disabled="itemsSelected.length==0" v-bind:class="{ disabled:itemsSelected.length==0}" class="btn btn-secondary mb-2 add-to-favorite-orders" data-toggle="modal" data-target="#addSOFavModal">
                                            <span class="icon"><i class="fa-icon fas fa-star mr-1"></i></span>
                                            {{tr('Add To Favorite Orders')}}
                                      </button>
                                          <button key="brn-4-step-1" :disabled="itemsSelected.length==0" v-bind:class="{ disabled:itemsSelected.length==0}" class="btn btn-success mb-2 nextBtn delivery-data" type="button" @click="nextStep($event)">
                                              <span>{{tr('Delivery Data')}}</span>
                                              <span class="icon"><i class="fa-icon fas fa-arrow-right"></i></span>
                                          </button>
                                      </div>
                                 </div>
                             </div>
                             <div class="col-12 setup-content" id="step-2" v-if="currentStep==2">
                                <div class="row">
                                    <div class="col-12 col-md-7">
                                          <div class="row" >
                                            <carDeliveryList @deliveryselected="calculateOrderTotals()" />
                                          </div>
                                          <div class="col-12">
                                        <div class="form-row m-0 mb-3 px-2 bordered">
                                           <h6 class="col-12 align-items-center d-flex"><i class="delivery-icon fas fa-credit-card fa-2x mr-3" style="font-size: 1.6em;"></i>Método de pago</h6>
                                           <div class="form-group col-12 col-md-6 px-2">
                                                <label class="col-form-label sr-only" >{{tr("PayTerm")}}</label>
                                                <select  class="form-control" @change="updateSelectValues('PayTerm')">
                                                    <option  :value="null" :selected="$store.state.order_PayTerm==null" disabled>Seleccione un método de pago</option>
                                                    <template v-for="payterm of payTerms">
                                                         <template v-if="$store.state.setting.AllowNotOnlyPayments && payterm.fields.RequireOnlinePayment">
                                                            <option :value="payterm.fields.PayTermCode" :selected="payterm.fields.PayTermCode==payTermSelectedCode" >{{payterm.fields.PayTermName}}</option>
                                                        </template>
                                                        <template v-else>
                                                           <option :value="payterm.fields.PayTermCode" :selected="payterm.fields.PayTermCode==payTermSelectedCode" >{{payterm.fields.PayTermName}}</option>
                                                        </template>
                                                    </template>
                                                </select>
                                           </div>
                                           <div class="col-12 payment-message px-2" v-if="$store.state.setting.AllowNotOnlyPayments!=true">
                                              <p class="mb-1">Sólo pago con tarjetas</p>
                                           </div>
                                       </div>
                                  </div>
                                </div>
                                    <div class="col-12 col-md-5">
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                 <thead>
                                                     <tr>   
                                                         <th scope="col">{{tr("Item")}}</th>
                                                         <th scope="col" class="text-right">{{tr("Price")}} {{showPriceLabel()}}</th>
                                                         <th scope="col" colspan="3" class="text-center">{{tr("Qty")}}</th>
                                                         <th scope="col" class="text-right">{{tr("Total")}} {{showPriceLabel()}}</th>
                                                         <th></th>
                                                     </tr>
                                                 </thead>
                                                 <tbody v-if="orderTemplate">
                                                    <template v-for="item in getOrderItems">
                                                         <template v-if="item.message">
                                                           <tr :key="'carItem-'+item.fields.ArtCode" :title="item.message" :class="{ 'text-danger': item.message }" >
                                                              
                                                               <td colspan="4">
                                                                  <a href="#" class="item-link" :class="{ 'text-danger': item.message }" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}} </a>
                                                               </td>
                                                               <td colspan="3">
                                                                  {{tr(item.message)}}
                                                               </td>
                                                                <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                                  <span class="row"  :title="tr('Delete Element from Car')">
                                                                      <i class="fas fa-trash"></i>
                                                                  </span>
                                                               </td>
                                                            </tr>
                                                         </template>
                                                         <template v-else>
                                                             <tr :key="'carItem-'+item.fields.ArtCode">
                                                              
                                                               <td @click="viewDetailItem(item)">
                                                                  <a href="#" class="item-link" data-toggle="modal" data-target="#carItemDetailModal"> {{item.fields.Name}}</a>
                                                               </td>
                                                              
                                                               <td scope="row" class="text-right" >
                                                                   <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                       <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                            {{number_format(item.fields.VATPrice,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                                       </template>
                                                                       <template v-else>
                                                                            {{number_format(item.fields.Price,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                                        </template>
                                                                   </template>
                                                                   <template v-else>
                                                                       --
                                                                   </template>
                                                               </td>
                                                               <td scope="row" colspan="3" class="center" >
                                                                  <div class="form-inline row  justify-content-center">
                                                                      <label class="sr-only form-control" @click="()=>itemQtyIncrement(item.fields.ArtCode)">
                                                                          <i class="fas fa-plus-circle waves-effect"></i>
                                                                      </label>
                                                                      <input :key="'carItemInput-'+item.fields.ArtCode" type="number" :id="item.fields.ArtCode" class="form-control col-8" min="1"  :max="getMaxStockByCode(item.fields.ArtCode)" :value="parseInt(item.fields.Qty)" @onfocus="autoResetBlur" @focusout="updateCarQty">
                                                                      <label class="sr-only form-control"@click="()=>itemQtyDecrement(item.fields.ArtCode)" >
                                                                          <i class="fas fa-minus-circle waves-effect"></i>
                                                                      </label>
                                                                   </div>
                                                               </td>
                                                               <td class="text-right">
                                                                   <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                       <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                            {{number_format(item.fields.RowTotal,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                                       </template>
                                                                       <template v-else>
                                                                            {{number_format(item.fields.RowNet,2)}} <small v-if="Currency"> {{Currency}}</small>
                                                                        </template>
                                                                   </template>
                                                                   <template v-else>
                                                                       --
                                                                   </template>
                                                               </td>
                                                               <td class="center" @click="()=>deleteItemFromCar(item.fields.ArtCode)">
                                                                  <span class="delet-icon"  :title="tr('Delete Element from Car')">
                                                                      <i class="fas fa-trash"></i>
                                                                  </span>
                                                               </td>
                                                             </tr>
                                                         </template>
                                                    </template>
                                                    <tr v-if="$store.getters.showSubTotalInOrderDetail">
                                                         <td colspan="9" class="text-right" >
                                                              <strong class="mr-2">Sub Total</strong>
                                                              <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                                  <strong>$ {{number_format(getOrderField('SubTotal',0),2)}} <small v-if="Currency"> {{Currency}}</small></strong>
                                                              </template>
                                                              <template v-else>
                                                                  --
                                                              </template>
                                                          </td>
                                                    </tr>
                                                    <tr v-if="getShipCost!=null">
                                                        <td colspan="7" class="text-left text-md-right">
                                                          <template>
                                                            <strong>{{tr("Ship Cost")}}: $ {{getShipCost}}</strong> 
                                                          </template>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="$store.getters.showPayTermSurchargeInOrder && getPayTermSurcharge !== null">
                                                        <td colspan="8" class="text-left text-md-right">
                                                             <strong>{{getPayTermSurchargeLable('MP')}}: $ {{getPayTermSurcharge}} </strong>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="getDiscountCoupon">
                                                        <td colspan="7" class="text-left text-md-right">
                                                          <template >
                                                            <strong>{{tr("Discount")}}: $ {{getDiscountCoupon}} </strong>
                                                          </template>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="$store.getters.showIVAInOrder && ($store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin)" class="pt-1">
                                                        <td colspan="8" class="text-left text-md-right">
                                                            <strong class="mr-2">Total IVA: $ {{number_format(getOrderField('VATTotal',0),2)}} <small v-if="Currency"> {{Currency}}</small></strong>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="$store.getters.showTaxInOrder && ($store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin)" class="pt-1">
                                                        <td colspan="8" class="text-left text-md-right">
                                                            <strong class="mr-2">Total Impuestos: $ {{number_format(getOrderField('TotalTax',0),2)}} <small v-if="Currency"> {{Currency}}</small></strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="7" class="text-left text-md-right">
                                                            <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                <strong class="mr-2">Total (IVA INC)</strong>
                                                           </template>
                                                           <template v-else>
                                                                <strong class="mr-2">Total (IVA EXC)</strong>
                                                            </template>
                                                            <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                                                               <template v-if="$store.getters.getSetting.ShowPriceWithIVA" >
                                                                    <strong>$ {{number_format(getOrderField('Total',0),2)}} <small v-if="Currency"> {{Currency}}</small></strong> 
                                                               </template>
                                                               <template v-else>
                                                                    <strong>$ {{number_format(getOrderField('SubTotal',0),2)}} <small v-if="Currency"> {{Currency}}</small></strong> 
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                --
                                                            </template>
                                                        </td>
                                                    </tr>
                                                 </tbody>
                                             </table>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                      <div class="text-right align-self-end">
                                          <button key="btn-1-step-2" class="btn btn-secondary prevBtn float-left mb-2 back-to-items-in-cart" type="button" @click="prevStep()">
                                                <span class="icon"><i class="fa-icon fas fa-arrow-left"></i></span>
                                                <span>{{tr('Back to Items in Cart')}}</span>
                                          </button>
                                          <button key="btn-2-step-2" @click="payAndClose($event)" v-bind:class="{'btn btn-success pay':true, disabled:itemsSelected.length==0}">{{tr('Pagar')}}</button>
                                      </div>
                                  </div>
                                </div>
                             </div>
                             <div class="col-12 setup-content my-4" id="step-3" v-if="currentStep==3">
                                     <div class="col-12 OrderResult">
                                         <template v-if="status === 'loading'">
                                             <div class="">
                                                 <h5 class="text-center">{{tr("Your Order is processing")}}.{{tr("Please Wait")}}.</h5>
                                             </div>
                                         </template>
                                         <template v-if="status === 'failure'">
                                             <h5 class="text-center">{{tr("The Sales Order can't be created")}}.</h5>
                                             <div v-if="result.error.length > 0" class="col-12 col-md-6 offset-md-3 error-message px-1">
                                                 <p v-for="error of result.error" class="text-center">{{tr(error)}}</p>
                                             </div>
                                             <button class="btn btn-secondary prevBtn float-left back-to-items-in-cart" type="button" @click="currentStep=1">
                                                    <span class="icon"><i class="fa-icon fas fa-arrow-left"></i></span>
                                                    <span>{{tr('Back to Items in Cart')}}</span>
                                             </button>
                                         </template>
                                         <template  v-if="status === 'completed'">
                                             <h5 class="text-center">{{ tr([successMessage,result.so.fields.SerNr]) }}.</h5>
                                             <div v-if="result.error.length > 0" class="col-12 col-md-6 offset-md-3 error-message my-3 px-1 pt-2 d-none">
                                                 <h5 class="text-center">{{tr("Information")}}:</h5>
                                                 <p v-for="error of result.error" class="text-center">
                                                    {{tr(error)}}
                                                </p>
                                             </div>
                                             <template v-if="(mpAvailable || redirectAvailable) && mpdata && mpdata.amount">
                                                <div class="col-12 col-md-6 offset-md-3 alert alert-warning px-1" role="alert" v-if="paymentMsg">
                                                    <template v-if="Array.isArray(paymentMsg)">
                                                         <p v-for="error of paymentMsg" class="text-center array">{{tr(error.description.charAt(0).toUpperCase() + error.description.slice(1))}}</p>
                                                    </template>
                                                    <template v-else>
                                                        <p class="text-center single">{{tr(paymentMsg.charAt(0).toUpperCase() + paymentMsg.slice(1))}}</p>
                                                    </template>
                                                </div>
                                                <template v-if="paymentIsProcess">
                                                    <div class="col-12 col-md-6 offset-md-3 alert alert-success text-center" role="alert">
                                                        <icon name="regular/check-circle" scale="5" class="col-6"/>
                                                        <h4 class="col-12 text-center mt-2">{{tr("The payment was approved successfully")}}</h4>
                                                        <strong class="d-block mt-2">{{tr('It may take a few minutes to be reflected')}}</strong>
                                                    </div>
                                                </template>
                                                <div class="row justify-content-center mt-4"  v-if="doOnlinePayment">
                                                    <template v-if="$store.getters.getSetting.MercadoPagoMode==1">
                                                        <mercadopagoFormCustom :info="mpdata" @payment-init="paymentInit" @payment-result="paymentProcess"></mercadopagoFormCustom>
                                                    </template>
                                                    <template v-else-if='$store.getters.getSetting.MercadoPagoMode==0'>
                                                        <mercadopagoCustom :info="mpdata" @payment-init="paymentInit" @payment-result="paymentProcess"></mercadopagoCustom>
                                                    </template>
                                                    <template v-else-if='$store.getters.getSetting.MercadoPagoMode==2'>
                                                        <redirectPaymentComponent :SO='result.so.fields.SerNr' :PayTermObj='getPayTermObj()'/>
                                                    </template>
                                                </div>
                                             </template>
                                             <div class="row justify-content-center mt-4">
                                               <router-link key="btn-1-step-3" to="/profile" class="btn btn-custom">{{tr('View Shopping List')}}</router-link>
                                             </div>
                                         </template>
                                     </div>
                             </div>
                          </div>
                      </div>
                  </div>
              </div>`;
    }

}

CarComponentB2C.registerComponent();
